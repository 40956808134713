.react-dropdown-select {
  padding: 8px 15px !important;
  box-sizing: border-box;
  min-height: 40px !important;
  height: 100%;
  border: 1px solid #909090 !important;
  outline: none !important;
  margin-bottom: 15px;
  line-height: 1;

  &:hover, &:focus, &:focus-within {
    border: 1px solid #3c3c3c !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &.topBarSelect {
    margin-bottom: 0;
    background-color: white;
    font-size: 12px;
    padding: 5px 10px !important;
    min-width: 120px;
    color: #3c3c3c;

    input {
      font-size: 12px !important;
    }
  }

  &.homePage {
    font-size: 14px;
  }

  &.detail-page-select {
    background-color: #00ABC4;
    border: none !important;
    min-height: 40px;
    color: white;
    border-radius: 0;

    &:hover, &:focus, &:focus-within {
      border: none !important;
    }

    .react-dropdown-select-dropdown {
      width: 100%;
      background-color: #00ABC4;
      color: white;
      border-radius: 0;
      border: none;

      .react-dropdown-select-item, .react-dropdown-select-item-selected {
        min-height: 30px;
        font-size: 12px;
        padding: 10px 15px;
      }

      .react-dropdown-select-item-selected, .react-dropdown-select-item:hover {
        background-color: #0C98AD !important;
        color: white;
      }
    }
  }

  .react-dropdown-select-input {
    min-width: 0;
    margin: 0;
    min-height: 0;
  }

  &.selected {
    .react-dropdown-select-input {
      width: 0;
      height: 0;
    }
  }


  .react-dropdown-select-content {
    line-height: 1.2;
  }

  .react-dropdown-select-dropdown {
    width: calc(100% + 2px);
    border-radius: 2px;
    top: 100%;

    .react-dropdown-select-item-selected {
      background: #00ABC4 !important;
    }
  }

  .react-dropdown-select-dropdown-handle {
    margin: 0 !important;
  }
}
