$colorPrimary: rgb(0, 171, 196);
$colorPrimaryLight: lighten($colorPrimary, 20%);
$colorActive: rgb(12, 152, 173);
$colorText: #3c3c3c;
$colorTextOnBackground: white;
$colorBackgroundGray: #ebebeb;
$colorBackground: white;
$colorTextGray: #909090;
$colorBlack: rgb(0, 0, 0);

$colorOverlayBackground: rgba(60, 60, 60, 0.6);

$colorSignalRed: rgb(232, 59, 59);
$colorSignalOrange: rgb(255, 174, 0);
$colorSignalGreen: rgb(108, 197, 12);
