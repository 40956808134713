@import 'style/variables.scss';

.CommunicationsTable {
  width: $widthContent;
  max-width: 100%;
  margin: $paddingLarge auto;
}

.columnDate {
  width: 12ex;
}

.columnKind {
  width: 20ex;
}

.columnDetail {
  width: 15ex;
  text-align: center !important;
}

.icon {
  text-decoration: none;
  font-size: 14px;
  color: $colorPrimary;
  padding-right: $paddingSmall;
}

.iconDetail {
  text-decoration: none;
  font-size: 14px;
  padding-right: $paddingSmall;
}

.detailLink {
  background-color: rgb(108, 197, 12);
  color: $colorTextOnBackground;
  text-decoration: none;
  padding: $paddingSmall $paddingDefault;
  font-weight: bold;
  cursor: pointer;
}

.detailOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  cursor: default;
}

.visible {
  display: flex;
}

.detailBox {
  background-color: $colorBackground;
  width: auto;
  max-width: 70%;
  height: auto;
  max-height: 70%;
  display: block;
  justify-content: center;
  align-items: center;
  color: $colorText;
  // border: 1px solid $colorActive;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
}

$barHeight: 35px;

.bar {
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  height: $barHeight;
  width: 100%;
  display: flex;
}

.close {
  background-color: $colorActive;
  color: $colorTextOnBackground;
  height: $barHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: $fontSizeDefault;
  font-weight: bold;
  padding: calc(#{$barHeight} / 3);
  margin-left: auto;
  width: $barHeight;
}

.content {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: $paddingXLarge;
  white-space: normal;
  font-weight: 400;
}

.titleText {
  display: flex;
  align-items: center;
  padding-left: $paddingLarge;
}

.iconTitle {
  @extend .icon;
  font-size: $fontSizeDefault;
  padding-right: $paddingDefault;
  color: $colorTextOnBackground;
}

.communicationText {
  color: $colorText;
  font-size: $fontSizeDefault !important;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .CommunicationsTable {
    width: 100%;
  }
}


@media only screen and (max-width: $mobileViewMaxWidth) {
  .detailBox {
    height: auto;
  }
  .content {
    padding: $paddingXLarge;
  }
}
