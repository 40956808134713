@import 'style/variables.scss';

.StatisticsPage {
  box-sizing: border-box;
  width: 100%;
}

.topPartBackground {
  width: 100%;
  background-color: $colorBackgroundGray;
  padding: 40px 0;
}

.topPart {
  margin: 0 auto;
  width: 100%;
  font-size: $fontSizeTitle;
  font-weight: 600;
  color: $colorPrimary;
  text-align: center;
}

.topPartText {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding: 10px 30px 0px;
  box-sizing: border-box;
}

.navigator {
  display: flex;
  flex-direction: row;
  width: 650px;
  margin: 0 auto;
  justify-content: space-between;
  padding: $paddingXLarge;
}

.navigationText {
  font-weight: bold;
}

.arrow {
  color: $colorPrimary;
  cursor: pointer;
}

.arrowDisabled {
  color: $colorTextGray;
  cursor: default;
}

.table {
  width: 500px;
  margin: 0 auto;
  padding: $paddingXLarge;
}

.td {
  width: 50%;
}

.tdValues {
  @extend .td;
  background-color: #d7fcfd;
}

.thValues {
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  font-weight: bold;
}

.ruka {
  width: 100%;
  margin: 0 auto;
  display: block;
  z-index: -1;
}

.averageTable {
  width: 500px;
  margin: $paddingXLarge auto 0;

  .spanTh {
    border-left: 1px solid #ebebeb;
  }

  .thValues {
    padding: 5px 10px;
    white-space: nowrap;
  }

  .tdValues {
    padding: 5px;
  }

  .secondTh {
    min-width: 110px;
    border-left: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    box-sizing: border-box;
  }
}


@media only screen and (max-width: $mobileViewMaxWidth) {
  .topPartBackground {
    padding: $paddingLarge 0;
  }
  .navigator {
    width: auto;
    padding: $paddingLarge;
  }
  .table {
    width: auto;
    max-width: 500px;
    padding: $paddingXLarge 0;
    overflow-x: auto;
  }
  .tableScroll {
    box-sizing: border-box;
    width: 100%;
    overflow-x: auto;
  }
}
