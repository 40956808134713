@import 'style/variables.scss';

.table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
}

.cell {
  padding: $paddingDefault;
  text-align: left;
  white-space: nowrap;
}

.th {
  @extend .cell;
  background-color: $colorBackgroundGray;
  font-weight: normal;
  white-space: nowrap;
}

.row {
  border-bottom: 1px solid $colorBackgroundGray;
}

.td {
  @extend .cell;
}

.firstColumn {
  font-weight: bold;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 5px;
}

.dots {
  display: flex;
  width: 30px;
  justify-content: center;
  margin: 0 2px;

  svg {
    fill: $colorText;
  }
}

.page {
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border: 1px solid $colorActive;
  padding: 5px;
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: $colorBackground;
  transition: background-color 250ms, color 250ms;

  &:hover {
    background-color: $colorPrimary;
    color: $colorTextOnBackground;
    transition: background-color 250ms, color 250ms;
  }
}

.active {
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  transition: background-color 250ms, color 250ms;
}

.previousPagination,
.nextPagination {
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    color: $colorBlack
  }
}
