@import 'style/variables.scss';

.DetailPageMenu {
  display: flex;
  width: $widthContent;
  max-width: 100%;
  background-color: $colorPrimary;
  margin: 0 auto;
  transform: translate(0, -50%);
}

.linkWrapper {
  position: relative;
}

.link {
  display: inline-block;
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  font-weight: bold;
  padding: 16px 32px;
  text-decoration: none;
}

.link.active {
  background-color: $colorActive;
}

$arrowSize: 8px;
.link.active:before {
  content: '';
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;
  border-top: $arrowSize solid $colorActive;
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - #{$arrowSize});
}

.link:hover {
  background-color: $colorActive;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .DetailPageMenu {
    transform: none;
  }
}
