@import 'style/variables.scss';

.RecommendPage {
  width: 100%;
}

.topPartBackground {
  width: 100%;
  background-color: $colorBackgroundGray;
  padding: 40px 0;
}

.topPart {
  margin: 0 auto;
  width: 100%;
  font-size: $fontSizeTitle;
  font-weight: 600;
  color: $colorPrimary;
  text-align: center;
}

.doporucitZnamemu {
  padding: $paddingXLarge;
  box-sizing: border-box;
  text-align: center;
}

.recommendPageContent {
  text-align: center;
  max-width: 900px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.text {
  padding: 40px 0;
  p {
    margin: 5px 40px;
  }
}

.recommendButton {
  min-width: 100px;
  color: $colorTextOnBackground;
  background: $colorPrimary;
  border: none;
  padding: $paddingDefault 2*$paddingDefault;
  margin: 0 $paddingDefault;
  font-weight: 600;
  font-size: $fontSizeSmall;
  composes: transitionDuration from 'style/effects.scss';

  &:active, &:hover {
    background: $colorActive;
  }
}

.image {
  width: 100%;
  padding: 10px 0;

  img {
    max-width:  900px;
    width: 70%;
    height: auto;
  }
}

.messageSent {
  text-align: center;
  padding: $paddingXLarge 0 $paddingXXLarge;

  svg {
    height: 70px;
    width: 70px;
    fill: $colorSignalGreen
  }

  p {
    margin: 20px 0 35px 0;
  }
}

.title {
  font-size: $fontSizeTitle;
  font-weight: 600;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .text {
    padding: 20px 0;
  }
  .image {
    img {
      width: 80%;
    }
  }
}
