$paddingDefault: 10px;
$paddingSmall: 5px;
$paddingLarge: 15px;
$paddingXLarge: 30px;
$paddingXXLarge: 40px;
$heightTopBar: 80px;
$heightFooter: 130px;
$widthForm: 350px;
$heightForm: 150px;
$widthContent: 1100px;

$tabletViewMaxWidth: 1023px;
$mobileViewMaxWidth: 799px;
$mobileViewHeightTopBar: 60px;
