@import 'style/variables.scss';

.circleGreen {
  color: $colorSignalGreen;
}

.circleOrange {
  color: $colorSignalOrange;
}

.circleRed {
  color: $colorSignalRed;
}

.circleGray {
  color: $colorTextGray;
}
