@import 'style/variables.scss';

.PostPage {
  width: 100%;
}

.topPartBackground {
  width: 100%;
  background-color: $colorBackgroundGray;
  padding: 40px 0 120px;
}

.topPart {
  margin: 0 auto;
  width: 100%;
  font-size: $fontSizeTitle;
  font-weight: 600;
  color: $colorPrimary;
  text-align: center;
}

.topPartText {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
}

.postsContainer {
  top: -80px;
  display: flex;
  justify-content: center;
  position: relative;
}

.postCardsContainer {
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.postCard {
  box-shadow: 0 0 10px 5px darken($colorBackgroundGray, 5%);
  width: 250px;
  background-color: $colorBackground;
  margin: 0 20px 40px;
  cursor: pointer;
  transition: box-shadow 200ms;
  text-decoration: none;
  color: inherit;

  &:hover {
    transition: box-shadow 200ms;
    box-shadow: 0 0 12px 10px darken($colorBackgroundGray, 6%);
  }
}

.postImage {
  width: 100%;
  height: 150px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.postTitle {
  font-size: $fontSizeDefault;
  color: $colorPrimary;
  font-weight: 700;
  padding: 10px 10px 5px 10px;
}

.postText {
  padding: 0 10px 10px 10px;
}

.postPagination {
  position: relative;
  top: -60px;
  justify-content: center;
}
