/*
Flaticon icon font: Flaticon
Creation date: 06/11/2020 13:39
*/

@font-face {
  font-family: 'Flaticon';
  src: url('./Flaticon.ttf') format('truetype'), url('./Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./Flaticon.svg#Flaticon') format('svg');
  }
}

@font-face {
  font-family: 'Flaticon2';
  src: url('./Flaticon2.ttf') format('truetype'), url('./Flaticon2.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon2';
    src: url('./Flaticon2.svg#Flaticon') format('svg');
  }
}

.fi:before {
  display: inline-block;
  font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi2:before {
  @extend .fi;
  font-family: 'Flaticon2';
}

.flaticon-home:before {
  @extend .fi;
}
.flaticon-logout:before {
  @extend .fi;
}
.flaticon-call:before {
  @extend .fi;
}
.flaticon-email:before {
  @extend .fi;
}
.flaticon-pdf-file:before {
  @extend .fi;
}
.flaticon-arrow-down-sign-to-navigate:before {
  @extend .fi;
}
.flaticon-hotel:before {
  @extend .fi;
}
.flaticon-user:before {
  @extend .fi;
}
.flaticon-visibility:before {
  @extend .fi;
}
.flaticon-info:before {
  @extend .fi;
}
.flaticon-left-arrow:before {
  @extend .fi;
}
.flaticon-right-arrow:before {
  @extend .fi;
}

.flaticon-home:before {
  content: '\f100';
}
.flaticon-logout:before {
  content: '\f101';
}
.flaticon-call:before {
  content: '\f102';
}
.flaticon-email:before {
  content: '\f103';
}
.flaticon-pdf-file:before {
  content: '\f104';
}
.flaticon-arrow-down-sign-to-navigate:before {
  content: '\f105';
}
.flaticon-hotel:before {
  content: '\f106';
}
.flaticon-user:before {
  content: '\f107';
}
.flaticon-visibility:before {
  content: '\f108';
}
.flaticon-info:before {
  content: '\f109';
}
.flaticon-left-arrow:before {
  content: '\f10a';
}
.flaticon-right-arrow:before {
  content: '\f10b';
}

.flaticon-writing:before {
  @extend .fi2;
}
.flaticon-sms:before {
  @extend .fi2;
}
.flaticon-visibility:before {
  @extend .fi2;
}
.flaticon-close:before {
  @extend .fi2;
}
.flaticon-writing:before {
  content: '\f100';
}
.flaticon-sms:before {
  content: '\f101';
}
.flaticon-visibility:before {
  content: '\f102';
}
.flaticon-close:before {
  content: '\f103';
}
