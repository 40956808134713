@import 'style/variables.scss';

.TopBar {
  display: flex;
  flex-direction: column;
}

.mainTopBar {
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  height: $heightTopBar;
  width: 100%;
  display: flex;
  box-shadow: 0px -15px 20px 5px $colorBlack;
  z-index: 1;
}

.logo {
  height: 100%;
  padding: $paddingDefault;
  padding-right: calc(2 * #{$paddingDefault});
  box-sizing: border-box;
  min-width: 236px; // when logo is loading other elements are jumping around to fill free space
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: $fontSizeSmall;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conductTitle {
  @extend .title;
}

.documentButtons {
  font-weight: bold;
  font-size: $fontSizeSmall;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colorActive;
  text-decoration: none;
  position: relative;
  height: $heightTopBar;
  box-sizing: border-box;
  gap: 12px;
}

.pickupDocuments {
  position: relative;
  cursor: pointer;
  img {
    filter: invert(1);
    width: 28px;
    height: 28px;
  }
}

.taxDocuments {
  position: relative;
  cursor: pointer;
  img {
    filter: invert(1);
    width: 24px;
    height: 24px;
    padding-bottom: 2px;
  }
}

.verticalDivider {
  margin-right: 2px;
  padding-top:10px;
  box-sizing: border-box;
  width: 1px;
  height: 80%;
  background-color: #ffffff;
}

.docBadge {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  background: #ee2f2f;
  text-align: center;
  border-radius: 50%;
  color: white;
  z-index: 1;
}

.link {
  text-decoration: none;
}

.iconHome {
  font-size: 24px;
}

.iconLogout {
  font-size: 14px;
}

.logoutName {
  padding-bottom: 2px;
  font-size: 13px;
}

.defaultCursor {
  cursor: default !important;
}

.pointerCursor {
  cursor: pointer !important;
}

.button {
  background-color: $colorActive;
  color: $colorTextOnBackground;
  height: $heightTopBar;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.titleButton {
  @extend .button;
  width: $heightTopBar;
}

.tenantSelect {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 20px;
}

.tenantTitle {
  padding-right: 5px;
  font-size: $fontSizeSmall;
  color: $colorBackground;
}

.tenantMobileTitle {
  font-size: $fontSizeSmall;
  color: $colorBackground;
  padding-bottom: 5px;
}

.selectWrapper {
  min-width: 270px;
  box-sizing: border-box;
}

.mobileSelectWrapper {
  width: 100%;
  box-shadow: none;
}

.logoutButton {
  @extend .button;
  font-size: $fontSizeSmall;
  font-weight: bold;
  padding: 12px 20px;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
}

.marginLeftAuto {
  margin-left: auto;
}

.mobileViewBar {
  display: flex;
  flex-direction: column;
  background-color: $colorPrimary;
  padding: $paddingLarge;
}

.documentsTable {
  color: $colorBlack;
}

.alreadyPicked {
  padding-top: 20px;
  text-align: center;
  color: $colorPrimary;
  font-size: 18px;
}
.noDocumentsToPick {
  font-size: 14px;
  color: $colorTextGray;
  text-align: center;
}
.notPickedDocuments {
  font-size: 14px
}
.alreadyPickedDocuments {
  color: $colorText;
  font-size: 14px
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .mainTopBar {
    height: $mobileViewHeightTopBar;
  }

  .titleButton, .logoutButton {
    height: $mobileViewHeightTopBar;
  }

  .documentButtons {
    height: $mobileViewHeightTopBar;
    padding: $paddingDefault
  }

  .logo {
    min-width: auto;
    padding-right: 10px;
  }

  .button {
    background: transparent;
  }

  .conductTitle {
    padding: $paddingLarge
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .titleButton, .logoutButton {
    width: 240px;
    flex-direction: row;
    justify-content: flex-end;

    .iconLogout {
      padding-left: 10px;
    }
  }

  .logoutButton {
    margin-left: auto;
  }

  .logo {
    width: 60px;
  }
}
