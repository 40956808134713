@import 'style/variables.scss';

.loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid $colorPrimary;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.2s linear infinite; /* Safari */
  animation: spin 1.2s linear infinite;
  margin: 0 auto;
  padding: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
