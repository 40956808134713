@import './variables.scss';

.transitionDuration {
  transition-duration: 200ms;
  &:hover {
    transition-duration: 200ms;
  }
}

.formBorder {
  border: 2px solid $colorPrimary;
}

.boxShadow {
  box-shadow: 2px 2px 10px 5px $colorBackgroundGray;
}

.boxShadowOnHover {
  &:hover {
    @extend .boxShadow;
    transition-property: box-shadow;
    transition-duration: 200ms;
  }
  transition-duration: 200ms;
}
