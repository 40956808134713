@import 'style/variables/colors.scss';
@import 'style/variables/layout.scss';

.YourPropertyManager {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: $paddingXLarge;
}

.title {
  font-size: 22px;
  font-weight: 500;
  color: $colorPrimary;
  padding-bottom: $paddingLarge;
}

.detail {
  display: flex;
  flex-direction: row;
}

.img {
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 80px;
  }
}

.information {
  display: flex;
  flex-direction: column;
  padding-left:$paddingDefault;
}

.name {
  color: $colorText;
  font-size: 14px;
  font-weight: 700;
}

.phone {
  color: $colorPrimary;
  font-size: 12px;
  font-weight: 700;
  padding: $paddingDefault 0;

  span::before {
    padding-right: 6px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.sendMessageButton {
  border: none;
  color: $colorPrimary;
  font-weight: 600;

  a {
    text-decoration: none;
    color: $colorPrimary;
  }

  span::before {
    padding-right: 6px;
  }
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .YourPropertyManager {
    width: auto;
    padding: $paddingDefault 0;
  }
  .sendMessageButton {
    display: flex;
    a {
      font-size: 12px
    }
  }
}
