@import 'style/variables.scss';

body {
  margin: 0;
  background-color: $colorBackground;
  font-family: 'Be Vietnam', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: none !important;
}

code {
  font-family: monospace;
}

button {
  font-family: 'Be Vietnam', sans-serif !important;
  font-weight: bold;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

input {
  font-size: $fontSizeDefault !important;
  color: $colorText !important;
}

.notFound {
  color: $colorPrimary;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: -webkit-fill-available;
}
