@import 'style/variables.scss';

.LoginPage {
  composes: formBorder from 'style/effects.scss';
  padding: $paddingLarge;
  width: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-weight: 700;
  font-size: $fontSizeTitle;
  color: $colorPrimary;
  padding: $paddingSmall 0 2*$paddingDefault;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .LoginPage {
    width: 100%;
    margin: 20px;
  }

  form > div {
    width: 80% !important;
  }
}
