@import "style/variables";

.form {
  padding: $paddingDefault;
  box-sizing: border-box
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.col {
  display: flex;
  width: 49%;
  box-sizing: border-box;
  justify-content: space-between;
}

.field {
  position: relative;
  display: flex;
  width: 49%;
  box-sizing: border-box;
  justify-content: space-between;

  &:focus-within, &.notEmpty, &:-webkit-autofill {
    label {
      top: -$paddingDefault;
      transition-property: top, transform, color;
      transition-duration: 300ms;
      transform: scale(0.85);
      color: $colorText;
    }
  }
}

.fieldRow {
  width: 100%;
}

.label {
  box-sizing: border-box;
  top: 8px;
  left: 8px;
  padding: 0 5px;
  transition-property: top,left, transform, color;
  transition-duration: 300ms;
  transform: scale(1);
  position: absolute;
  background: $colorBackground;
  pointer-events: none;
  z-index: 1;
  color: $colorTextGray !important;
}

.textField, .passwordField {
  border: 1px solid $colorTextGray;
  border-radius: 2px;
  padding: $paddingDefault $paddingLarge;
  width: 100%;
  outline: none;
  margin-bottom: $paddingLarge;
  transition: border 200ms, margin-bottom 200ms;
  height: fit-content;

  &:focus, &:focus-within, &:hover {
    outline: none;
    border: 1px solid $colorText;
    border-radius: 2px;
    box-sizing: border-box;
    transition: border 200ms, margin-bottom 200ms;
  }

  &::placeholder {
    color: transparent;
  }
}

.checkBoxWrapper {
  padding-bottom: $paddingLarge;
}

.checkBoxField {
  margin: $paddingDefault $paddingDefault $paddingDefault 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.checkBoxLabel{
  margin: $paddingDefault 0;
}

.passwordField {
  padding-right: $paddingXLarge ;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dateField {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.datePickerLabelWrapper {
  position: relative;
  width: 49%;

  &:focus-within, &.notEmpty  {
    label {
      top: -$paddingDefault;
      left: 4px;
      transition-property: top, left, transform, color;
      transition-duration: 300ms;
      transform: scale(0.85);
      color: $colorText
    }
  }
}

.selectField {
  width: 100%;
  margin: $paddingXXLarge 0;
  composes: boxShadow from 'style/effects.scss';
  box-shadow: none;

  div:first-child {
    box-shadow: none !important;

    &:hover, &:focus-within {
      border-color: $colorText !important;
    }
  }
  span:hover {
    background: transparentize($colorPrimary, 0.9);
  }
}

.selecInputField {
  position: relative;
  width: 49%;
  height: auto;
}

.passwordIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: $colorTextGray;
  transition: color 200ms;
}

.visible {
  color: $colorText;
  transition: color 200ms;
}

.errorField {
  border: 1px solid $colorSignalRed;
  border-radius: 2px;
  margin-bottom: $paddingXLarge;
  transition: margin-bottom 200ms;

  &:focus, &:focus-within {
    border: 1px solid $colorSignalRed;
  }
}

.errorMessage {
  position: absolute;
  font-size: 10px;
  color: $colorSignalRed;
  height: 100%;
  bottom: -38px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $paddingDefault;
}

.changeText {
  font-size:$fontSizeSmall;
  color: $colorTextGray;
  padding: $paddingDefault 0,
}

.button {
  min-width: 100px;
  color: $colorTextOnBackground;
  background: $colorPrimary;
  border: none;
  padding: $paddingDefault $paddingLarge;
  margin: 0 $paddingDefault;
  font-weight: 600;
  font-size: $fontSizeSmall;
  composes: transitionDuration from 'style/effects.scss';
  text-transform: uppercase;

  &:active, &:hover {
    background: $colorActive;
  }
}

.cancelButton {
  color: $colorPrimary;
  background: $colorTextOnBackground;
  border: 2px solid $colorPrimary;
  composes: transitionDuration from 'style/effects.scss';

  &:active, &:hover {
    background: $colorPrimary;
    color: $colorTextOnBackground;
  }
}

.divider {
  padding: $paddingSmall 0 $paddingLarge;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: $colorPrimary;
  font-weight: 500;

  &::before, &::after {
    margin: 0;
    content: '';
    flex: 1;
    border-bottom: 1px solid $colorPrimary;
  }
  &::before {
    margin-right: $paddingDefault;
  }
  &::after {
    margin-left: $paddingDefault;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .row {
    width: 100% !important;
    flex-direction: column;
  }

  .field {
    width: 100%;
  }

  .col {
    width: 100%;
    flex-direction: column;
  }
  .datePickerLabelWrapper {
    width: 100%;
    margin-bottom: $paddingLarge;
  }

  .buttons {
    margin: $paddingDefault 0;
  }
}
