@import 'style/variables.scss';

.InspectionsTable {
  width: $widthContent;
  max-width: 100%;
  margin: $paddingLarge auto;
  overflow-x: auto;
}

.images {
  display: flex;
  flex-direction: row;

  img {
    max-height: 50px;
    width: auto;
    padding-right: $paddingSmall;
    cursor: pointer;

    &:last-child {
      padding-right: 0;
    }
  }
}

.iconPdf {
  text-decoration: none;
  color: $colorSignalRed;
  cursor: pointer;
  font-size: 20px;
}

.showAllText {
  padding: $paddingSmall;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $colorPrimary;
  composes: transitionDuration from 'style/effects.scss';

  &:hover {
    color: $colorActive;
  }
}

.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  background: $colorOverlayBackground;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wrapper {
  min-width: 500px;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.arrowButton {
  padding: $paddingXXLarge;
  cursor: pointer;
  font-size: 32px;
  width: 100%;
  z-index: 1;
  composes: transitionDuration from 'style/effects.scss';

  &:hover {
    color: $colorBlack;
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.carouselImages {
  position: absolute;
  width: auto;
  max-width: 75%;
  max-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: inherit;
    cursor: default;
    z-index: 999;
  }
}

.number {
  position: absolute;
  bottom: -30px;
  color: $colorBlack;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .InspectionsTable {
    width: 100%;
  }
}


@media only screen and (max-width: $mobileViewMaxWidth) {
  .carousel {
    width: 100%;
  }
  .carouselImages {
    max-width: 80%;
  }
  .wrapper {
    min-width: auto;
  }
  .arrowButton {
    padding: $paddingDefault
  }
}
