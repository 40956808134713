@import 'style/variables.scss';

.HomePageMenuItem {
  border: 1px solid $colorBackgroundGray;
  border-top-width: 2px;
  height: 110px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin: $paddingDefault;
  composes: boxShadowOnHover from 'style/effects.scss';
  cursor: pointer;
}

.img {
  object-fit: contain;
  width: 100%;
  height: 50px;
}

.text {
  font-weight: bold;
  font-size: $fontSizeSmall;
  text-align: center;
  padding: $paddingDefault;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .HomePageMenuItem {
    margin: $paddingSmall
  }
}
