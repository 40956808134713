@import 'style/variables.scss';

.dialogOverlay {
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  top: 0;
  background: $colorOverlayBackground;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogContent {
  background: $colorBackground;
  width: 65%;
  min-width: 600px;
  max-width: 1200px;
  height: auto;
  max-height: 90vh;
  overflow-x: auto;
  padding: $paddingLarge;
  position: absolute;
  box-sizing: border-box;
}

.dialogTitle {
  padding: $paddingDefault;
  font-size: $fontSizeTitle;
  font-weight: 700;
  color: $colorPrimary;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: $paddingLarge;
  right: $paddingLarge;
  font-size: $fontSizeDefault;
  line-height: 0;
  cursor: pointer;
  font-weight: 600;
  color: lighten($colorText, 10%);

  &:hover {
    color: $colorText;
  }
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .dialogOverlay {
    height: auto;
    min-height: 100%;
  }

  .dialogContent {
    width: 100%;
    min-width: auto;
    max-height: initial;
    height: 100%;
    overflow-x: auto;
  }
}
