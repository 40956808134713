@import 'style/variables.scss';

.Footer {
  background-color: $colorBackgroundGray;
  height: $heightFooter;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $paddingDefault;
  box-sizing: border-box;
}

.logo {
  height: 36px;
  padding: 12px;
  display: block;
  margin: 0 auto;
}

.text {
  display: block;
  font-size: $fontSizeDefault;
  color: $colorTextGray;
  margin: 0 auto;
  text-align: center;
}

.textEmphasis {
  color: $colorPrimary;
  font-weight: bold;
}
