@import 'style/variables.scss';

.MyAccount {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: flex-start;
}

.topPart {
  width: 100%;
  background-color: $colorBackgroundGray;
  margin-bottom: $paddingLarge;
}

.accountDetailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editButtons {
  margin-bottom: $paddingXLarge;
}

.accountDetail {
  width: $widthContent;
  max-width: 100%;
  padding: $paddingXXLarge 0 $paddingXLarge;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  table:first-child {
    width: 55%;
  }
  table:nth-child(2) {
    width: 45%;
  }
}

.title {
  font-weight: bold;
  width: 35%;
  padding: $paddingSmall $paddingXLarge $paddingSmall 0;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .accountDetail {
    padding: $paddingLarge;
    width: 100%;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .accountDetail {
    flex-direction: column;

    table {
      width: 100% !important;
    }
  }

  .topPart {
    margin-bottom: 0;
  }

  .title {
    padding: $paddingSmall $paddingLarge $paddingSmall 0;
  }
}
