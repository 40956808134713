@import 'style/variables.scss';

.MyProperty {
  display: flex;
  flex-direction: row;
  width: $widthContent;
  max-width: 100%;
  margin: $paddingDefault auto 0;
}

.propertyList {
  width: 60%;
  padding-right: $paddingDefault;
}

.title {
  font-size: 22px;
  font-weight: 500;
  color: $colorPrimary;
  padding-bottom: 15px
}

.property {
  width: 100%;
  padding: $paddingLarge;
  box-sizing: border-box;
  margin-bottom: $paddingDefault;
  composes: boxShadow from 'style/effects.scss';
  font-weight: 700;
  cursor: pointer;
  transition-duration: 200ms;

  &:hover {
    transition-duration: 200ms;
    box-shadow: 2px 2px 10px 5px darken($colorBackgroundGray, 5%);
  }
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .MyProperty {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: $paddingLarge;
  }
  .propertyList {
    width: 100%;
  }
}
