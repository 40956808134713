@import 'style/variables.scss';

.PaymentsTable {
  width: $widthContent;
  max-width: 100%;
  margin: $paddingLarge auto;
  margin-bottom: $paddingXXLarge;
}

.firstColumn {
  font-weight: bold;
  font-size: $fontSizeDefault !important;
}

.circleGreen {
  color: $colorSignalGreen;
}

.circleOrange {
  color: $colorSignalOrange;
}

.circleRed {
  color: rgb(232, 59, 59);
}

.circleGray {
  color: $colorTextGray;
}

.helpIcon {
  width: 16px;
  height: 16px;
}

.helpTooltip {
  visibility: hidden;
  width: 240px;
  white-space: normal;
  background-color: #151515;
  color: #f8f8f8;
  text-align: center;
  border-radius: 6px;
  padding: 4px;
  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 0;
  top: -5px;
  right: 110%;
  margin-left: -100px;

  &::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #151515;
  }
}

.outcomeDateTitle {
  cursor: pointer;
  align-items: center;
  gap: 8px;
  position: relative;
  display: inline-block;

  &:hover .helpTooltip {
    visibility: visible;
  }
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .PaymentsTable {
    width: 100%;
  }
}
