@keyframes onAutoFillStart { from {background-color: initial} to {background-color: initial}}
@keyframes onAutoFillCancel { from {background-color: initial} to {background-color: initial}}


input:-webkit-autofill {
  // Expose a hook for JavaScript when autofill is shown
  // JavaScript can capture 'animationstart' events
  animation-name: onAutoFillStart;
  // Make the background color become yellow really slowly
  transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  // Expose a hook for JS onAutoFillCancel
  // JavaScript can capture 'animationstart' events
  animation-name: onAutoFillCancel;
}

.is-autofilled {
  label {
    top: -10px;
    transition-property: top, transform, color;
    transition-duration: 300ms;
    transform: scale(0.85);
    color: #3C3C3C;
  }
}
