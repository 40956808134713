@import 'style/variables.scss';

.ContractsTable {
  width: $widthContent;
  max-width: 100%;
  margin: $paddingLarge auto;
  position: relative;
}

.firstColumn {
  font-weight: bold;
  font-size: $fontSizeDefault !important;
}

.iconPdf {
  text-decoration: none;
  color: $colorSignalRed;
  cursor: pointer;
  font-size: 20px;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .ContractsTable {
    width: 100%;
  }
}
