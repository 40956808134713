@import 'style/variables.scss';

.AccountingsTable {
  width: $widthContent;
  max-width: 100%;
  margin: $paddingLarge auto;
}

.columnDates {
  // width: 12ex;
}

.columnTenant {
  // width: 20ex;
}

.columnApprove {
  // width: 15ex;
  text-align: center !important;
}

.approveLink {
  background-color: rgb(108, 197, 12);
  color: $colorTextOnBackground;
  text-decoration: none;
  padding: $paddingSmall $paddingDefault;
  font-weight: bold;
  cursor: pointer;
}

.approved {
  @extend .approveLink;
  background-color: $colorBackground;
  color: rgb(108, 197, 12);
  cursor: default;
}

.iconPdf {
  text-decoration: none;
  color: $colorSignalRed;
  cursor: pointer;
  font-size: 20px;
}

.undercharge {
  color: $colorSignalRed;
  font-weight: bold;
}

.overcharge {
  color: $colorSignalGreen;
  font-weight: bold;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .AccountingsTable {
    width: 100%;
  }
}
