@import 'style/variables.scss';

.Layout {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  font-size: $fontSizeDefault;
  color: $colorText;
}

.loginContent {
  align-items: center;
}
